import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  ApplyCancellationPromotionRequestDto,
  ChangePromocodeRequestDto,
  DeletePromocodeRequestDto,
  PauseSubscriptionRequestDto,
  PaymentDto,
  PurchaseCourseRequestDto,
  SubscribeToCourseRequestDto,
  UnpauseSubscriptionRequestDto,
  ValidateConfirmationTokenRequestDto,
  ValidateConfirmationTokenResponseDto,
} from 'api/generated';

import { ChangePromoCodeResponse, DefaultRejectValue, ExtraParamsThunkType, PaymentsResponse } from './types';

export const subscribeCourseThunk = createAsyncThunk<
  PaymentsResponse,
  SubscribeToCourseRequestDto & { useToken?: boolean },
  ExtraParamsThunkType<DefaultRejectValue>
>(
  'payments/subscribeToCourse',
  async ({ courseId, level, period, source, promocode, useToken }, { rejectWithValue }) => {
    const subscribeParams = {
      courseId,
      level,
      period,
      source,
      promocode,
    };

    try {
      //@ts-ignore
      const response = await api.Payments.paymentsControllerSubscribeToCourse(subscribeParams, { useToken });

      const { data, status } = response;

      if (status > 399) {
        throw data;
      }

      return data;
    } catch (error) {
      return rejectWithValue(error as DefaultRejectValue);
    }
  },
);

export const purchaseCourseThunk = createAsyncThunk<
  PaymentsResponse,
  PurchaseCourseRequestDto & { useToken?: boolean },
  ExtraParamsThunkType<DefaultRejectValue>
>('payments/purchaseCourse', async ({ courseId, source, promocode, useToken }, { rejectWithValue }) => {
  const purchaseParams = {
    courseId,
    source,
    promocode,
  };
  try {
    //@ts-ignore
    const { data, status } = await api.Payments.paymentsControllerPurchaseCourse(purchaseParams, { useToken });

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const changePromocodeThunk = createAsyncThunk<
  ChangePromoCodeResponse,
  ChangePromocodeRequestDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('payments/changePromoCode', async ({ promocode, invoiceId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Payments.paymentsControllerChangePromocode({ promocode, invoiceId });

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const deletePromocodeThunk = createAsyncThunk<
  ChangePromoCodeResponse,
  DeletePromocodeRequestDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('payments/changePromoCode', async ({ invoiceId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Payments.paymentsControllerDeletePromocode({ invoiceId });

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const pauseSubscriptionThunk = createAsyncThunk<
  PaymentDto,
  PauseSubscriptionRequestDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('payments/pauseSubscription', async ({ courseId, months }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Payments.paymentsControllerPauseSubscription({ courseId, months });

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const applyCancellationPromotionThunk = createAsyncThunk<
  void,
  ApplyCancellationPromotionRequestDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('payments/apply-cancellation-promotion', async ({ courseId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Payments.paymentsControllerApplyCancellationPromotion({ courseId });

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const unpauseSubscriptionThunk = createAsyncThunk<
  PaymentDto,
  UnpauseSubscriptionRequestDto,
  ExtraParamsThunkType<DefaultRejectValue>
>('payments/unpause/subscription', async ({ courseId }, { rejectWithValue }) => {
  try {
    const { data, status } = await api.Payments.paymentsControllerUnpauseSubscription({ courseId });

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const validateTokenThunk = createAsyncThunk<
  ValidateConfirmationTokenResponseDto,
  ValidateConfirmationTokenRequestDto & { useToken: boolean },
  ExtraParamsThunkType<DefaultRejectValue>
>('payments/validate-confirmation-token', async ({ confirmationToken, courseId, useToken }, { rejectWithValue }) => {
  const payload = {
    confirmationToken,
    courseId,
  };
  try {
    //@ts-ignore
    const { data, status } = await api.Payments.paymentsControllerValidateConfirmationToken(payload, { useToken });

    if (status > 399) {
      throw data;
    }

    return data;
  } catch (error) {
    return rejectWithValue(error as DefaultRejectValue);
  }
});

export const actions = {
  subscribeCourse: subscribeCourseThunk,
  purchaseCourse: purchaseCourseThunk,
  unpauseSubscription: unpauseSubscriptionThunk,
  changePromoCode: changePromocodeThunk,
  deletePromoCode: deletePromocodeThunk,
  pauseSubscription: pauseSubscriptionThunk,
  applyCancellationPromotion: applyCancellationPromotionThunk,
  validateToken: validateTokenThunk,
};
